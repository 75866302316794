<template>
  <div 
    class="grid grid-cols-1 md:grid-cols-12"
    :class="gapClass"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    gap?: 'sm' | 'base' | 'lg' | 'custom'
  }>(), { 
    gap: 'base',
  }
)

const gapClass = computed(() => {
  if (props.gap === 'custom') return ''
  return props.gap === 'sm' ? 'gap-y-3 sm:gap-4 lg:gap-6'
    : props.gap === 'lg' ? 'gap-y-6 sm:gap-8 lg:gap-12'
    : 'gap-y-4 sm:gap-6 lg:gap-8'
})

</script>

<style>

</style>